<template>
    <TopBar title="视频搜索" />
    <div class="app-main">
        <div class="searchbar" style="margin: 20px;">
            <a-input-search
                v-model:value="value"
                placeholder="请输入关键词"
                size="large"
                @search="onSearch"
            >
                <template #enterButton>
                    <a-button type="primary">
                        <svg t="1717673724465" class="icon" viewBox="0 -80 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4873" width="20" height="20"><path d="M918.613333 873.386667l-200.277333-200.32a352.597333 352.597333 0 1 0-45.269333 45.269333l200.32 200.277333a32 32 0 0 0 45.226666-45.226666zM160 448a288 288 0 1 1 288 288 288.341333 288.341333 0 0 1-288-288z" fill="#fbfcfe" p-id="4874"></path></svg>
                    </a-button>
                </template>
            </a-input-search>
        </div>
        <a-divider orientation="left" orientation-margin="12px">
            热搜排行
        </a-divider>
        <div class="keyword-list" style="width: 100%;">
            <!--a-button size="large" class="keyword-button" block><a-tag color="#f35" >1</a-tag>词哈哈(902834)</a-button-->
            <a-button
                v-for="(item, index) in hotKeyword"
                :key="item.id"
                @click="$router.push({path:'/searchlist', query: {'keyword': item.keyword}})"
                size="large"
                class="keyword-button"
                block>
                <a-tag :color="sortColor(index)">
                {{ index+1 }}
                </a-tag>
                {{ item.keyword }}<span style="color: #aabbcc;">({{ item.search_count }})</span>
            </a-button>
        </div>
        <div class="ad-banner">
            <a v-for="item in adverBanner" :key="item.id" :href="item.link" target="_blank">
                <img :src="item.img" />
            </a>
        </div>
    </div>
    <BottomNavbar current="search" />
</template>

<script>
import TopBar from '../components/TopBar.vue';
import BottomNavbar from '../components/BottomNavbar.vue';

export default {
    components: {
        TopBar, BottomNavbar,
    },
    data() {
        return {
            hotKeyword: [],
            adverBanner: [],
        }
    },
    created() {
        window.scrollTo(0, 0);
        this.fetchHotKeyword();
    },
    mounted() {
        
    },
    methods: {
        fetchHotKeyword() {
            const url = 'https://mgapi.fkdlapp.live/getData/hotkeyword';
            fetch( url )
                .then(response => response.json())
                .then(data => {
                    // 请求成功，处理数据
                    this.hotKeyword = data['data']['keywords'];
                    this.adverBanner = data['data']['adver_banner'];
                })
                .catch(error => {
                    // 请求失败，处理错误
                    this.error = error.message;
                });
        },
        sortColor(sortnum) {
            switch(sortnum) {
                case 0:
                    return "#f35";
                case 1:
                    return "red";
                case 2:
                    return "orange";
                case 3:
                    return "green";
                default:
                    return "";
            }
        },
        onSearch(keyword) {
            if ( keyword ) {
                this.$router.push({path: '/searchlist', query: {'keyword': keyword}});
            }
        },
    },
}
</script>


<style scoped>
.app-main {
    padding: 0 0 108px 0;
}
.keyword-list {
    padding: 0 12px 20px 12px;
}
.keyword-button {
    margin-right: 12px;
    margin-bottom: 17px;
    text-align: left;
}
</style>