<script setup>
import { ref } from 'vue'
import { SoundFilled } from '@ant-design/icons-vue'

const notifyContent = ref('请牢记蘑菇社区回家地址：mgsq.cc');
/*
const props = defineProps({
    Content: String
});
if ( props.Content ) {
    notifyContent.value = props.Content;
}
*/
</script>

<template>
    <div id="notifybar" class="notify">
        <div class="notify-icon"><SoundFilled /></div>
        <div class="notify-scroll">
            <span class="notify-text">{{ notifyContent }}</span>
        </div>
    </div> 
</template>


<style scoped>
@keyframes scrollLeft {
  0% { transform: translateX(0); }
  100% { transform: translateX(-100%); }
}
#notifybar {
  height: 36px;
  background-color: #f6f6f6;
  /*border-radius: 6px;*/
  padding-left: 20px;
  /*margin-top: 12px;*/
}
.notify-icon {
    height: 36px;
    width: 32px;
    background-color: #f6f6f6;
    border-radius: 6px;
    position: absolute;
    left: 12px;
    z-index: 1;
    text-align: center;
    padding-top: 10px;
    color: #ee3399;
}
.notify-scroll {
  padding-top: 10px;
  flex: 1;
  overflow: hidden;
}
span.notify-text {
  color: #ee3399;
  display: block;
  width: auto;
  white-space: nowrap;
  padding-left: 100%;
  padding-right: 100%;
  animation:  scrollLeft 14s linear infinite;
}
</style>
