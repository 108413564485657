<script>
//import { Modal } from 'ant-design-vue';
import HeaderGroup from '../components/HeaderGroup.vue';
//import NotifyBar from '../components/NotifyBar.vue';
import BottomNavbar from '../components/BottomNavbar.vue';

export default {
  components: {
    HeaderGroup, BottomNavbar, //NotifyBar
  },
  data() {
    return {
      notice: '公告：蘑菇回家地址：https://mgsq.cc',
      carouselItems: [],
      adsURL: {
        live : 'https://6r6j.xyz/lvcha/?ref=mogu',
        pao: 'https://6r6j.xyz/pao/?ref=mogu',
        ai: 'https://6r6j.xyz/ai/?ref=mogu',
        qipai: 'https://k720.xyz/',
        vns: 'https://6r6j.xyz/vns/?ref=mogu',
        xpj: 'https://6r6j.xyz/vns/?ref=mogu',
      },
    }
  },
  created() {
    window.scrollTo(0, 0);
    const userdata = localStorage.getItem('userdata');
    if (!userdata) {
      this.$router.push({path: '/login'});
    }
    this.fetchAdsURL();
    console.log(this.adsURL);
  },
  methods: {
    fetchAdsURL() {
      const url = 'https://mgapi.fkdlapp.live/getData/adsurl';
      fetch( url )
        .then(response => response.json())
        .then(data => {
          // 请求成功，处理数据
          if (data['success']) {
            this.adsURL = data['data'];
            console.log(this.adsURL);
          }
        })
        .catch(error => {
          // 请求失败，处理错误
          //this.error = error.message;
          console.log(error.message);
        });
    },
    onSearch(keyword) {
        if ( keyword ) {
            this.$router.push({path: '/appsearchlist', query: {'keyword': keyword}});
            window.scrollTo(0, 0);
        }
    },
  },
  computed: {
    sliderAD() {
        const allAdvers = this.$store.state.advers;
        return allAdvers.filter(item => item.type == 1);
    },
  }
}

</script>

<template>
<!--header>
<h3>破解</h3>
</header-->
<HeaderGroup title="平台列表" />
<main>

<!--h3 style="font-size: 18px; color: #223344; margin: 20px 0 12px 20px;">聚合搜索</h3>
<div class="searchbar">
    <a-input-search
      v-model:value="value"
      placeholder="输入关键词立马搜片"
      size="large"
      @search="onSearch"
    >
      <template #enterButton>
        <a-button type="primary">
            <svg t="1717673724465" class="icon" viewBox="0 -80 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4873" width="20" height="20"><path d="M918.613333 873.386667l-200.277333-200.32a352.597333 352.597333 0 1 0-45.269333 45.269333l200.32 200.277333a32 32 0 0 0 45.226666-45.226666zM160 448a288 288 0 1 1 288 288 288.341333 288.341333 0 0 1-288-288z" fill="#fbfcfe" p-id="4874"></path></svg>
        </a-button>
      </template>
    </a-input-search>
</div-->

<!--h3 style="font-size: 18px; color: #223344; margin: 20px 0 12px 20px;">平台列表</h3-->
<div class="applist">
<!--a-card>
<a-card-title>平台列表</a-card-title>
<a-card-body style="padding: 24px; text-align: center;"-->
  <a-row :gutter="[4,16]">
    <!--a-col :span="6">
        <div>
            <div class="applogo">
                <img :src="require('@/assets/adimgs/ky.100x100.gif')" />
            </div>
            <h4>开元棋牌</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div>
            <div class="applogo">
                <img :src="require('@/assets/applogos/logo-o20kwae83fwo17.gif')" />
            </div>
            <h4>同城约啪</h4>
        </div>
    </a-col-->
    <a-col :span="6">
        <a :href="adsURL.ai" target="_blank" style="display: block;">
            <div class="applogo">
                <img :src="require('@/assets/adimgs/ai.256x256.gif')" />
            </div>
            <h4>AI脱衣</h4>
        </a>
    </a-col>
    <a-col :span="6">
        <a :href="adsURL.live" target="_blank" style="display: block;">
            <div class="applogo">
                <img :src="require('@/assets/adimgs/live.lvcha.128x128.png')" />
            </div>
            <h4>绿茶直播</h4>
        </a>
    </a-col>
    <a-col :span="6">
        <a :href="adsURL.vns" target="_blank" style="display: block;">
            <div class="applogo">
                <img :src="require('@/assets/adimgs/vns.png')" />
            </div>
            <h4>澳门威尼斯</h4>
        </a>
    </a-col>
    <a-col :span="6">
        <a :href="adsURL.vns" target="_blank" style="display: block;">
            <div class="applogo">
                <img :src="require('@/assets/adimgs/vns.gif')" />
            </div>
            <h4>威尼斯人</h4>
        </a>
    </a-col>
    <!--a-col :span="6">
        <div>
            <div class="applogo">
                <img :src="require('@/assets/adimgs/live.lvcha.128x128.png')" />
            </div>
            <h4>大秀直播</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div>
            <div class="applogo">
                <img :src="require('@/assets/adimgs/space.128x128.png')" />
            </div>
            <h4>广告招租</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div>
            <div class="applogo">
                <img :src="require('@/assets/adimgs/space.128x128.png')" />
            </div>
            <h4>广告招租</h4>
        </div>
    </a-col-->
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'mise', 'apptitle': '蜜色视频'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/mise128x128.png')" />
            </div>
            <h4>蜜色视频</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'xhs', 'apptitle': '小黄书'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/xhs192x192.png')" />
            </div>
            <h4>小黄书</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'hxc', 'apptitle': '含羞草'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/hxc256x256.png')" />
            </div>
            <h4>含羞草</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'fanhao', 'apptitle': '番号社'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/fanhao128x128.png')" />
            </div>
            <h4>番号社</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'tan', 'apptitle': '91探花'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/tan128x128.png')" />
            </div>
            <h4>91探花</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'hj', 'apptitle': '海角乱伦'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/hj128x128.png')" />
            </div>
            <h4>海角乱伦</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'lls', 'apptitle': '萝莉社'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/lls128x128.png')" />
            </div>
            <h4>萝莉社</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'aw', 'apptitle': '暗网禁区'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/aw128x128.png')" />
            </div>
            <h4>暗网禁区</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'cm', 'apptitle': '草莓视频'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/caomei128x128.png')" />
            </div>
            <h4>草莓视频</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'wuye', 'apptitle': '午夜TV'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/wuye128x128.png')" />
            </div>
            <h4>午夜TV</h4>
        </div>
    </a-col>
    <!--a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'xj', 'apptitle': '香蕉视频'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/xj128x128.png')" />
            </div>
            <h4>香蕉视频</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/postlist', query: {'appname': 'mogu', 'apptitle': '蘑菇社区'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/mogu128x128.png')" />
            </div>
            <h4>蘑菇社区</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'bale', 'apptitle': '芭乐视频'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/bale128x128.png')" />
            </div>
            <h4>芭乐视频</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'sigua', 'apptitle': '丝瓜视频'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/sigua128x128.png')" />
            </div>
            <h4>丝瓜视频</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'mt', 'apptitle': '蜜桃TV'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/mitao128x128.png')" />
            </div>
            <h4>蜜桃TV</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'siwa', 'apptitle': '丝袜TV'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/siwa128x128.png')" />
            </div>
            <h4>丝袜TV</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/appvideolist', query: {'appname': 'cn', 'apptitle': '雏鸟视频'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/cn128x128.png')" />
            </div>
            <h4>雏鸟视频</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div>
            <div class="applogo">
                <img :src="require('@/assets/applogos/tao128x128.png')" />
            </div>
            <h4>红杏视频</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/postlist', query: {'appname': 'mogu', 'apptitle': '蘑菇社区'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/mogu128x128.png')" />
            </div>
            <h4>蘑菇短视频</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/shortview', query: {'appname': 'paofu'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/paofu128x128.png')" />
            </div>
            <h4>泡芙短视频</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/shortview', query: {'appname': 'dy'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/dy128x128.png')" />
            </div>
            <h4>抖阴短视频</h4>
        </div>
    </a-col>
    <a-col :span="6">
        <div @click="this.$router.push({path: '/shortview', query: {'appname': '91dsp'}})">
            <div class="applogo">
                <img :src="require('@/assets/applogos/91dsp128x128.png')" />
            </div>
            <h4>91短视频</h4>
        </div>
    </a-col-->
  </a-row>
  <!--/a-card-body>
  </a-card-->
</div>

</main>
<BottomNavbar current="applist" />
</template>



<style scoped>
/*
header {
  height: 52px;
  background-color: #001122;
  color: #f0f2f6;
}
header h3 {
  text-align: center;
  line-height: 52px;
}
*/
main {
  padding-bottom: 80px;
}
.searchbar {
  padding: 0 20px;
}
.applist {
  margin-top: 12px;
  padding: 12px;
}
.applist h4 {
  color: #445566;
  font-weight: normal;
  text-align: center;
}
.applist img {
  width: 100%;
  border-radius: 12px;
}
.applogo {
  padding: 0 12px 4px 12px;
}
</style>
