import { createRouter, createWebHashHistory } from 'vue-router'
import HotVideoList from '../views/HotVideoList.vue'
import AppList from '../views/AppList.vue'
//import ShortView from '../views/ShortView.vue'
import LiveChannel from '../views/LiveChannel.vue'
import LiveRoom from '../views/LiveRoom.vue'
//import PostList from '../views/PostList.vue'
//import PostVideo from '../views/PostVideo.vue'
import AppVideo from '../views/AppVideo.vue'
import AppVideoList from '../views/AppVideoList.vue'
import AppSearchIndex from '../views/AppSearchIndex.vue'
import AppSearchList from '../views/AppSearchList.vue'
//import VideoPlay from '../views/VideoPlay.vue'
import UserHome from '../views/UserHome.vue'
import MyVip from '../views/MyVip.vue'
import LoginView from '../views/LoginView.vue'
import PasswordUpdate from '../views/PasswordUpdate.vue'
import DownloadApp from '../views/DownloadApp.vue'

const routes = [
    {path: '/', name: 'hotlist', component: HotVideoList},
    {path: '/applist', name: 'applist', component: AppList},
//    {path: '/shortview', name: 'shortview', component: ShortView},
    {path: '/livechannel', name: 'livechannel', component: LiveChannel},
    {path: '/liveroom', name: 'liveroom', component: LiveRoom},
//    {path: '/postlist', name: 'postlist', component: PostList},
//    {path: '/postvideo', name: 'postvideo', component: PostVideo},
    {path: '/appvideolist', name: 'appvideolist', component: AppVideoList},
    {path: '/searchindex', name: 'searchindex', component: AppSearchIndex},
    {path: '/searchlist', name: 'searchlist', component: AppSearchList},
    {path: '/appvideo', name: 'appvideo', component: AppVideo},
    {path: '/userhome', name: 'userhome', component: UserHome},
    {path: '/myvip', name: 'myvip', component: MyVip},
    {path: '/login', name: 'login', component: LoginView},
    {path: '/password', name: 'password', component: PasswordUpdate},
    {path: '/downloadapp', name: 'downloadapp', component: DownloadApp},
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
